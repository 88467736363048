.css-11bufkx-MuiPaper-root-MuiAlert-root {
  background-color: #e52e71!important;
  color: rgb(252 252 252)!important;
}
.verification{
  background-color: #e5e5f7;
opacity: 0.8;
background-image:  radial-gradient(#444cf7 0.5px, transparent 0.5px), radial-gradient(#444cf7 0.5px, #e5e5f7 0.5px);
background-size: 20px 20px;
background-position: 0 0,10px 10px;
}